









































































































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Breadcrumb from '@/components/Breadcrumb.vue'
import axios from 'axios'
import PressObj from '@/interfaces/PressObj'
import PressList from '@/components/PressList.vue'
import NotFound from '@/views/NotFound.vue'
import { getPressList } from '@/lib/listModules'

@Component({
  components: {
    Breadcrumb,
    NotFound,
    PressList
  }
})
export default class PressDetail extends Vue {
  article = ''
  pressList: Array<PressObj> = []
  relatedContents: Array<PressObj> = []
  detailPress: PressObj | null = null
  shareURL = ''
  isExistDetail = true

  async mounted(): Promise<void> {
    this.article = await this.getArticleHTML()
    this.pressList = await getPressList()

    this.detailPress = this.getDetailPress(this.pressList)
    this.relatedContents = this.getRelatedContents(
      this.detailPress,
      this.pressList
    )
    this.shareURL = location.origin + this.$route.path
    if (this.detailPress) {
      document.title = `${this.detailPress.title} | STOCK POINT株式会社`
    }
  }

  async getArticleHTML(): Promise<string> {
    const yy: string = this.$route.params.yymmdd_nn.slice(0, 2)
    const mm: string = this.$route.params.yymmdd_nn.slice(2, 4)
    const dd: string = this.$route.params.yymmdd_nn.slice(4, 6)
    const nn: string = this.$route.params.yymmdd_nn.slice(7, 9)

    const res = await axios
      .get(`/press/${yy}/${mm}/${dd}/${nn}.html`)
      .then(function (res) {
        return res.data
      })
      .catch(function (e) {
        console.log(e)
        return false
      })

    if (!res) {
      this.isExistDetail = false
      return ''
    } else {
      return res
    }
  }

  getDetailPress(pressList: Array<PressObj>): PressObj | null {
    const detailPress = pressList.find(
      (press: PressObj) => press.yymmdd_nn == this.$route.params.yymmdd_nn
    )
    if (detailPress) {
      return detailPress
    } else {
      return null
    }
  }

  getRelatedContents(
    detailPress: PressObj | null,
    pressList: Array<PressObj>
  ): Array<PressObj> {
    if (detailPress) {
      const relatedYMDN: Array<string> = detailPress.relatedContents
      if (relatedYMDN) {
        return pressList.filter(
          (press: PressObj) =>
            press.yymmdd_nn && relatedYMDN.includes(press.yymmdd_nn)
        )
      }
    }
    return []
  }
}
